.next-particle {
	/* position: relative; */
	display: flex;
	align-content: center;
	justify-content: center;
	/* margin-top: -150px; */
	/* background-color: #4ecfff; */
	/* max-width: 500px;
	max-height: 500px; */
	/* border: solid 1px red; */
	/* border-radius: 500px; */
}
